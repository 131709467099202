import { Layout } from '@components/layout';
import { Post, Ebook } from '@components/standard';
import { IPostPage } from '@standardTypes';
import React from 'react';

interface IPageContext {
  pageContext: {
    page: IPostPage;
    prevPost: (posts: IPostPage, idx: number) => any;
    nextPost: (posts: IPostPage, idx: number) => any;
  };
}

const PostPage: React.FC<IPageContext> = ({ pageContext }): JSX.Element => {
  const { page, prevPost, nextPost } = pageContext;
  //   const { categories } = page;
  //   const postCategory = categories.nodes[0];

  return (
    <Layout {...page}>
      <Post page={page} prevPost={prevPost} nextPost={nextPost} />
      <Ebook />
    </Layout>
  );
};

export default PostPage;
